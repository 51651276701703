.login {
    z-index: 2000;
}

.login-title {
    color: black;
    background-color: rgb(230, 230, 230);
    font-size: 2rem;
    font-family: 'Great Vibes', cursive;
}

.login-sub-title {
    font-size: 0.7rem;
    font-family: 'Montserrat', sans-serif;
}

.login-label {
    font-size: .8rem;
    font-weight: bold;
}

.login-input {
    width: 92%;
    font-size: .8rem;
}

.login-error-message {
    color: red;
    font-size: 0.8rem;
    font-weight: bold;
}

.login-ok-message {
    color: rgb(20, 150, 20);
    font-size: 1rem;
    font-weight: bold;
}

.react-modal-body {
    position: relative;
    background-color: rgb(240, 240, 240);
    border-radius: 4px;
    z-index: 103;
    min-height: 200px;
    display: none;
}


.loginFadeOut {
    animation-name: loginFadeOut;
    animation-duration: 2s 
}

.forgotten-password {
    transition: all 0.3s;
    color: rgb(61, 110, 150);
}

.forgotten-password:hover {
    cursor: pointer;
    color: rgb(25, 55, 80);
}

@keyframes loginFadeOut {

    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}