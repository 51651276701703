.acccount {
    margin-top: 60px;
    height: 130vh;
}

.account-title {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 2px 2px 5px #aaa;
    transition: all .5s;
}

.account-title:first-letter {
    font-family: 'Great Vibes', cursive;
    font-size: 2.8rem;
    margin-right: 10px;
    font-style: none;
}

.account-active-link {
    background-color: rgb(71, 200, 255) !important;
}


.account-custom-link {
    cursor: pointer;
    background-color: rgb(174, 228, 252) !important;
    transition: all .5s;
}

.account-custom-link:hover {
    background-color: rgb(71, 200, 255) !important;
}

.account-tab-title {
    color: rgb(114, 84, 39);
}

@media (max-width:500px) {


    .account-title {      
        font-size: 1rem;
    }
    .account-title:first-letter {
        font-size: 2rem;
        margin-right: 0px;
    }

    .account {
        margin-top: 45px;
    }
}