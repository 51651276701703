/* CONTACT */
.contact {
    height: 135vh;
    width: 100%;
    background: linear-gradient(105deg, rgba(21, 31, 32, 1) 0%, 
              rgba(21, 31, 32, .95) 50%, transparent 90%), 
                url(../img/Contact.jpg) center/cover;
    
    background-color: #000;
    /*background: url(../images/Contact.jpeg) center/cover;*/
  }

  .contact-title {
    font-family: 'Great Vibes', cursive;
    font-size: 1.5rem;
    margin-top: 5rem;
    font-style: none;
}

.contact-title:first-letter {
    font-family: 'Great Vibes', cursive;
    font-size: 2rem;
    margin-right: 10px;
    font-style: none;
}

.contact-separator {
    width: 20%;
    background-color: blanchedalmond;
}

.contact-name {
    color: rgb(230, 209, 182)
}

.contact-value {
    color: rgb(242, 232, 220)
}

.contact-message {
    font-family: 'Great Vibes', cursive;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-style: none;
}

.contact-form {
    width: 40rem;
    margin-top: 1rem;
    margin-left: 5rem;
}

.contact-message:first-letter {
    font-family: 'Great Vibes', cursive;
    font-size: 2rem;
    margin-right: 10px;
    font-style: none;
}


.input {
    background: transparent;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    transition: all .8s;
    color: #ddd;
}

.input:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
    border-bottom: 2px solid #f5593D;
}

.label {
    color: rgb(255, 241, 241);
    margin-left: 0px;
    font-size: 13px;
  }

  @media (max-width:992px) {

    .contact-form {
        width: 35rem;

    }
  }

  @media (max-width:768px) {

    .contact-form {
        width: 25rem;

    }
  }

  @media (max-width:550px) {

    .contact-form {
        width: 21rem;

    }
  }

  @media (max-width:420px) {

    .contact {
        height: 180vh;
    }

    .contact-form {
        width: 18rem;
    }
  }

  @media (max-width:380px) {

    .contact {
        height: 200vh;
    }

    .contact-form {
        width: 14rem;
    }
  }

  @media (max-width:340px) {

    .contact {
        height: 240vh;
    }
    .contact-form {
        width: 12rem;
    }
  }


.callCloudFunctionMessage {
    color: rgb(150, 115, 0);
    font-size: 18px;
}

.callCloudFunctionMessageOK {
    color: rgb(41, 126, 62);
    font-size: 18px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}
  
@keyframes blinker {  
    50% { opacity: 0; }
}