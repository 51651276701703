.input-add-category {
    width: 50%;
}

@media (max-width:768) {
    .input-add-category {
        width: 75%;
    }
}

@media (max-width:500px) {
    .input-add-category {
        width: 100%;
    }
}