.cart-hover {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(210, 192, 174);
    border: .1rem solid rgba(87, 35, 35, 0.075);
    box-shadow: .4rem .4rem rgba(109, 83, 18, 0.75);
}

.cart-hover-image-container {
    background-color: rgb(0, 0, 0);
    border: .1rem solid rgba(87, 35, 35, 0.075);
    box-shadow: .2rem .2rem rgba(145, 112, 29, 0.75);
    transition: 1s;
}

.cart-hover-image {
    width: 6rem;
    height: auto;
    background-color: rgb(0, 0, 0);
}

/*
.cart-hover-image-container:hover {
    transform: scale(5.5) translate(-40px, 30px);

}*/

.cart-hover-title {
    color: black;
    font-size: .8rem;
    margin-right: 20px;
    margin-top: .4rem;
}
.cart-hover-price {
    color: rgb(7, 112, 114);
    font-weight: 800;
    font-size: .8rem;
    margin-left: 20px;
}

.cart-hover-separator {
    height: .5rem;
    background-color: rgb(123, 98, 74);
}

.cart-hover-total {
    color: black;
    font-size: 1.2rem;
    margin-right: 20px;
    margin-top: .4rem;
}

.cart-hover-total-price {
    color: rgb(113, 84, 10);
    font-size: 1.2rem;
    margin-right: 20px;
    margin-top: .4rem;
}
#cartHoverImage {
    display: block;
  }
  
  /*#cartHoverImage:hover + #cartHoverDescription  {
    display:block;
    
  }*/

.cart-hover-description {
    display: block;
    position: absolute;
    left: -23rem;
    top: 0rem;
    padding: 1rem;
    background-color: rgb(131, 90, 35);
    color: rgb(255, 255, 255);
    z-index: 2000;
    transition: all .8s;
    opacity: 0;
    font-size: .8rem;
}

.cart-hover-description:hover {
    width: 30rem;
    opacity: 1;
}

.cart-hover-image-description {
    width: 10rem;
    height: 50%;
    background-color: rgb(0, 0, 0);
}

