.cart-detail {
    margin-top: 60px;
}

.cart-no-products-container {
    background-color: rgb(231, 226, 226);
}

.cart-no-products {
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 20px;
    margin-top: .4rem;
}

.cart-no-product-link {
    color: rgb(129, 81, 18);
    font-weight: 800;
    transition: all 0.3s;
}

.cart-no-product-link:hover {
    opacity: 0.5;
}