.about {
    height: 100vh;
    width: 100%;
    background: linear-gradient(rgba(0,0,0, .2), rgba(0,0,0, .3)), 
                url(../img/Presentation.jpg) no-repeat center center/cover;

    transition: all 2s;
}

.about-card {
    width: 70%;
    margin-top: 60px;
    font-size: 1.8rem;
    background-color: rgb(31, 23, 23);
    opacity: 0.8;
    border-radius: 2%;
    border: .1rem solid rgba(255, 255, 255, 0.075);
    box-shadow: O 0 .8rem rgba(0, 0, 0, 0.75);
}
.about-anim1 {
    animation-name: animCard1;
    animation-duration: 2s;
}

.about-anim2 {
    animation-name: animCard2;
    animation-duration: 2s;
}

.page-about-header {
    margin-top: 135px;
    font-size: 1.6rem;
    color: rgb(255, 255, 255);
    margin-bottom: 15px;
}

.page-about-header-Montserrat {
    font-family: 'Montserrat', sans-serif;
}

.page-about-header-Dancing {
    font-family: 'Great Vibes', sans-serif;
    letter-spacing: 2px;
}

.about-name {
    font-family: "Dancing Script";
    font-size: 1.4rem;
    color: rgb(55, 122, 153);
}

.about-description {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: rgb(221, 228, 231);
}

.about-avatar-img {
    width: 115px;
    height: auto;
    transition: width .5s ease;
} 

.list-space {
    line-height: 15px !important;
}

.display-column {
    display: none;
}

.display-row {
    display: block;
}

@keyframes animCard1 {

    0% {
        transform: translateX(-400px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 0.8;
    }
}

@keyframes animCard2 {

    0% {
        transform: translateX(400px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 0.8;
    }
}


@media (max-width:1191px) {

    .about-avatar-img  {
        width: 95px;
    }

}



@media (max-width:992px) {

    .about-card {
        margin-top: 60px;
    }

    .about-avatar-img  {
        width: 120px;
        margin-top: 10px;
    }

    .page-about-header {
        margin-bottom: 0px;
    }

    .about-name {
        font-size: 1.5rem;
        margin-top: 15px;
    }

    .about-description {
        font-family: 'Montserrat', sans-serif;
        font-size: .8rem;
        color: rgb(221, 228, 231)
    }

    .display-row {
        display: none;
    }

    .display-column {
        display: block;
    }
}

@media (max-width:500px) {

    .about {
        height: 150vh;
        background-attachment: fixed;
    }
    .about-card {
        margin-top: 20px;
        width: 82%;

    }
    .page-about-header {
        width: 80%;
        margin-top: 65px;
        margin-bottom: 10px;
    }
}
@media (max-width:320px) {


    .display-row {
        display: none;
    }

    .display-column{
        display: block;
    }

    .about {
        height: 200vh;
    }

    .about-card { 
        width: 85%;
    }

    .about-description {
        font-size: 1rem;
    }

    .about-avatar-img  {
        width: 100px;
    }
    
    .list-space {
        line-height: 15px !important;
    }
}