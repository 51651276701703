
.dollar-field {
    margin-left: 12px;
}

.file-image {
    width: 15rem;
    height: auto;
}

.images-container {
    margin-top: 1rem;
    margin-right: 1rem;
    width: 18rem;
    /**border: 1px solid rgb(172, 146, 146);*/
    display: inline-block;
    background-color: rgb(41, 35, 35);
    border-radius: 2%;
    border: .1rem solid rgba(210, 190, 190, 0.075);
    box-shadow: 0rem 0rem 0rem rgba(240, 236, 236, 0.75);
}

.spinner {
    width: 24px;
    height: 24px;
}

.callCloudFunctionMessageOK {
    color: rgb(41, 126, 62);
    font-size: 18px;
}

.callCloudFunctionMessageNOK {
    color: rgb(247, 143, 143);
    font-size: 18px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

.modal-preview {
    position: absolute;
    left: -0.5%;

}

.input {
    background: transparent;
    border-color: transparent;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    transition: all .8s;
    color: #ddd;
}
  
.input:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
    border-bottom: 2px solid #f5593D;
}

.textarea {
    background: transparent;
    border-color: white;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    transition: all .8s;
    color: #ddd;
}

.textarea {
    background: transparent;
    border-color: white;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
    transition: all .8s;
    color: #ddd;
}
  
@keyframes blinker {  
    50% { opacity: 0; }
}


@media (max-width:2500px) {
    .modal-preview {
        top: 0;
    }
}

@media (max-width:499px) {
    .dollar-field {
        margin-top: 6px;
        margin-left: 0px;
    }
}

@media (max-width:500px) {
    .modal-preview {
        top: 105rem;
        left: 6%;
        transform: scale(0.90);
        background-color: rgb(117, 103, 103);
    }
}