.admin {
    margin-top: 100px;
}

.tab-title {
    color: rgb(114, 84, 39);
}

.custom-link {
    cursor: pointer;
    background-color: rgb(245, 245, 220);
    transition: all .5s;
}

.active-link {
    background-color: rgb(193, 193, 134) !important;
}

.custom-link:hover {
    background-color: rgb(193, 193, 134) !important;
}

.tab-item-mt {
    margin-top:  0px;
}

.text-header-form {
    font-size: 1.2rem;
    font-weight: 500;
    color: antiquewhite
}

.text-explain-delete {
    font-size: 1rem;
    font-weight: 200;
    color: rgb(218, 194, 163)
}

@media (max-width:450px) {
    .tab-item-mt {
        margin-top: 8px;
    }
  }