.spinnerSeach {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width:600px) {

    .spinnerSeach {
        flex-direction: column;
    }
}