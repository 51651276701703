body {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background-color: rgb(9, 12, 14)
}

a {
    text-decoration: none;
}

.public-DraftEditor-content {
    min-height: 10rem;
    }

button.navbar-toggler:focus { outline: 1px none; }

.container { 
  
    width: 100%;
}

