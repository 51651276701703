
.register {
    z-index: 2000;
}

.register-title {
    color: black;
    background-color: rgb(230, 230, 230);
    font-size: 2rem;
    font-family: 'Great Vibes', cursive;
}

.register-sub-title {
    font-size: 0.7rem;
    font-family: 'Montserrat', sans-serif;
}

.register-label {
    font-size: .8rem;
    font-weight: bold;
}

.register-input {
    width: 92%;
    font-size: .8rem;
}

.register-error-message {
    color: red;
    font-size: 0.8rem;
    font-weight: bold;
}

.register-ok-message {
    color: rgb(20, 150, 20);
    font-size: 1rem;
    font-weight: bold;
}

.react-modal-body {
    position: relative;
    background-color: rgb(240, 240, 240);
    border-radius: 4px;
    z-index: 103;
    min-height: 200px;
    display: none;
}


.registerFadeOut {
    animation-name: registerFadeOut;
    animation-duration: 2s 
}

.option-label {
    font-size: .8rem !important;
}

@media screen and (max-height: 800px) {
    .registerModal {
        position: absolute;
        top: 0rem;
    }
}

@keyframes registerFadeOut {

    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}