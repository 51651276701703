
.home-background {
    height: 100vh;
    width: 100%;
    background: linear-gradient(rgba(0,0,0, .2), rgba(0,0,0, .3)), 
                url(../img/Presentation.jpg) no-repeat center center/cover;

    transition: all 1s;
}

/* banner */
.banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.banner-headTitle {
    animation-name: anim;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-fill-mode: backwards;    
    font-size: 1.6rem;
}

.banner-title {
    animation-name: anim;
    animation-duration: 2s;
    font-family: "Dancing Script";
    font-size: 3.1rem;
    transition: all 1s;
}

.banner-subTitle {
    animation-name: anim;
    animation-duration: 2.5s;
    animation-delay: 1s;
    animation-fill-mode: backwards; 
    color: rgb(245, 215, 164); 
    font-weight: 500;
    transition: all 1s;
}

.names {
    animation-name: anim;
    animation-duration: 1.5s;
    animation-delay: .2s;
    animation-fill-mode: backwards;    
    position: absolute;
    top: 18%;
    left: 70%;
    font-family: "Great Vibes";
    font-size: 2.8rem;
    color: rgb(125, 183, 189);
    transition: opacity .5s;
    display: block;
}

.names:first-letter {
    animation-name: anim;
    animation-duration: 1.5s;
    animation-delay: .2s;
    animation-fill-mode: backwards;    
    position: absolute;
    top: 18%;
    left: 70%;
    font-family: "Dancing Script";
    font-size: 3.5rem;
    color: rgb(125, 183, 189);
    transition: opacity .5s;
    display: block;
}

.heart-beat {
    display: block;
}

@keyframes anim {

    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width:1100px) {
    .home-background {
        background: linear-gradient(to bottom, rgba(0,0,0, .3), rgba(0,0,0, .9)), 
                    url(../img/Presentation.jpg) no-repeat center center/cover;
    }

    .banner-title, .banner-subTitle {
        margin-top: 2rem;
    }

}

@media (max-width:1100px) { 
    .names {
        opacity: 0;
        display: none;
    }
}

@media (max-width:350px) { 

    .banner {
        margin-top: 20px;
    }

    .heart-beat {
        display: none;
    }
}
/* end of banner */

/* end header */