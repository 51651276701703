.contact-link {
    color: rgb(17, 103, 153);
    text-decoration: none;
    transition: all 0.3s;
}

.contact-link:hover {
    color: rgb(255, 255, 255);
}

.product-price {
    position: absolute;
    right: 3rem;
    bottom: 3.5rem;
}

.date-publication {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.7rem;
    font-weight: 100;
}

.manage-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.manage-checkbox {
    position: absolute;
    bottom: 4.5rem;
}

.label-checkbox-active {
    color: #ed7921;
}

.label-checkbox-sold {
    color: #8e1a05;
}

.label-checkbox-new {
    color: #05698e;
}

.label-checkbox-reserved {
    color: #9821ed;
}

.delete-confirm-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
}

.delete-confirm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.callCloudFunctionMessageOK {
    color: rgb(41, 126, 62);
    font-size: 18px;
}

.callCloudFunctionMessageNOK {
    color: rgb(247, 143, 143);
    font-size: 18px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

.ribbon-sold {
	background-color: #8e1a05;
	color: white;
	width: 8rem;
	height: 1.7rem;
	font-size: .8rem;
	font-weight: 700;
	padding-top: .3rem;
	position: absolute;
	right: -2.3rem;
	top: 1rem;
	transform: rotate(45deg);
    box-shadow: 0 0 .2rem rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.ribbon-new {
	background-color: #05698e;
	color: white;
	width: 8rem;
	height: 1.7rem;
	font-size: .8rem;
	font-weight: 700;
	padding-top: .3rem;
	position: absolute;
	right: -2.3rem;
	top: 1rem;
	transform: rotate(45deg);
    box-shadow: 0 0 .2rem rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.ribbon-inactive {
	background-color: #ed7921;
	color: white;
	width: 8rem;
	height: 1.7rem;
	font-size: .8rem;
	font-weight: 700;
	padding-top: .3rem;
	position: absolute;
	right: -2.3rem;
	top: 1rem;
	transform: rotate(45deg);
    box-shadow: 0 0 .2rem rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.ribbon-reserved {
	background-color: #9821ed;
	color: white;
	width: 8rem;
	height: 1.7rem;
	font-size: .8rem;
	font-weight: 700;
	padding-top: .3rem;
	position: absolute;
	right: -2.3rem;
	top: 1rem;
	transform: rotate(45deg);
    box-shadow: 0 0 .2rem rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    transition: all .3s !important;
  }


.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%
}

.shopping-icon {
    width: 2.5rem;
    height: auto;
    transition: .3s all;
}

.shopping-icon:hover {
    opacity: .7;
}

.button-shoppingfocus, .button-shopping:focus {
    outline: 0;
    box-shadow: none!important;
}

@media (max-width:768px) {

    .product-price {
        position: absolute;
        right: 1.2rem;
        bottom: 2rem;
    }
  }


  @media (max-width:578px) {

    .product-price {
        position: absolute;
        right: 3rem;
        bottom: 1rem;
    }
  }


  @media (max-width:450px) {

    .product-price {
        position: absolute;
        right: 2rem;
        bottom: 2rem;
    }

    .manage-checkbox {
        position: absolute;
        bottom: 4rem;
    }
  }

  @media (max-width:325px) {

    .product-price {
        position: absolute;
        right: 0.9rem;
        bottom: 2.5rem;
    }
  }