.cart-products {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(231, 226, 226);
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.cart-body {
    z-index: 1;
}

.cart-img {
    z-index: 1000;
}

.cart-products-image {
    width: 10rem;
    padding: 0.3rem; 
    margin: 0.3rem;
    background-color: rgb(65, 22, 22);
    transition: all .4s;
}

.cart-products-image:hover {
    transform: scale(2.6) translate(20%, 0%);
}
.cart-img:hover {
    z-index: 2000;
}

.cart-products-price-container {
    justify-content: flex-end;
}

.cart-products-title {
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 20px;
    margin-top: .4rem;
}

.cart-products-desciption {
    color: rgb(88, 83, 83);
    margin-right: .7rem;
    font-size: .9rem;
}

.cart-products-price {
    color: rgb(7, 112, 114);
    font-weight: 800;
    font-size: 1rem;
}

.cart-products-total {
    color: black;
    font-size: 1.2rem;
    margin-right: 20px;
    margin-top: .4rem;
}

.cart-products-total-price {
    color: rgb(113, 84, 10);
    font-size: 1.2rem;
    margin-right: 20px;
    margin-top: .4rem;
    font-weight: bold;
}

.delete-product-cart-button {
    background-color: rgb(231, 226, 226);
    border: none;
}
.delete-product-cart-button.focus, .delete-product-cart-button:focus {
    outline: 0;
    box-shadow: none!important;
}

.cart-products-back {
    color: rgb(129, 81, 18);
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s;
}

.cart-products-back:hover {
    opacity: .7;
}

.nav-back {
    text-decoration: none;
}

.nav-back:hover {
    text-decoration: none;
}

@media (min-width:968px) {

    .cart-products-price, .cart-products-desciption {
        margin-right: 0.8rem;
    }
}

@media (max-width:968px) {

    .cart-products-price, .cart-products-desciption {
        margin-right: -0.6rem;
    }

    .cart-products-desciption {
        margin-right: 1rem;
    }
}


@media (max-width:500px) {


    .cart-products-image:hover {
        transform: scale(2.6) translate(50%, 0%);
    }

    .cart-products {
        margin-top: 0rem;
    }

    .cart-products-image {
        width: 9rem;
    }

    .cart-products-title {
        color: black;
        font-size: 1.1rem;
        font-weight: bold;
        margin-right: 20px;
        margin-top: .6rem;
    }
}

@media (max-width:430px) {

    .cart-products-image {
        width: 6rem;
        background-color: rgb(219, 211, 211);
    }
}

@media (max-width:380px) {

    .cart-products-image {
        width: 5rem;
        background-color: rgb(219, 211, 211);
    }

    .cart-products-title {
        font-size: 0.8rem;
    }

    .cart-products-desciption {
        color: rgb(88, 83, 83);
        font-size: .8rem;
    }

    .cart-products-price {
        font-size: 0.8rem;
    }

    .cart-products-total {
        font-size: 1rem;
        font-weight: 600;
    }

    .cart-products-total-price {
        font-size: 1rem;
        font-weight: 800;
    }
}