
/* navbar */
.nav-menu {
    background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,0, .5));
    padding: 0px;
    transition: all .7s;
}

.navbar {
    min-height: 40px;
  }

.nav-button {
    padding: 0 15px;
    background-color: black;
}
  
.navbar-brand {
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
}

.navbarIcon {
    width: 1.5rem;
    height: auto;
    transition: .3s all;
}

.navbarIcon:hover {
    opacity: .7;
}

.navbar-brand-text {
    font-size: 1.5rem;
    font-family: 'Great Vibes';
    color: rgb(125, 183, 189);
    transition: .3s all;
}

.navbar-brand-text-first-letter {
    font-size: 1.8rem;
    font-family: 'Dancing Script';
    color: rgb(125, 183, 189);
    transition: .3s all;
}

.navbar-brand-text:hover {
    color: rgb(201, 175, 175);
}

.menu-item {
    font-size: 1rem;
    letter-spacing: 1px;
    color: #eee;
    cursor: pointer;
    transition: color .5s;
}

.login-link {
    color: #fff;
}

.navbar-nav, .nav-link.active, .navbar-nav .nav-link:hover {
    color: #70aed2;
}

.nav-active {
    color: #70aed2;
}

.fa-shopping-cart {
    font-size: 1.2rem;
    color: rgb(210, 148, 41);
    transition: all .3s;
}

.fa-shopping-cart:hover {
    color: #70aed2 !important;   
}

.shopping-container {
    position: relative;
}

.shopping-cart-badge {
    position: absolute;
    top: -.5rem;
    left: 1rem;
    width: 1rem;
    height: 1rem;
    background-color: #6b4306;
    border-radius: 50%;
    font-size: .7rem;
    color: white;
}

.icon-shop-small-screen {
    display: none;
}


.link-login:hover {
    color: #70aed2;
}

.link-login {
    color: rgb(60, 159, 10);
    transition: color .5s;
}

.link-signin {
    background: #175a94;
    color: #fff;
    transition: color .5s;
}

.link-signin:hover {
    background: #175a94;
    color: #aaa;
}

.link-logout {
    color: rgb(245, 133, 133);
    transition: color .5s;
}

.link-logout:hover {
    color: #70aed2;
}

.link-personnal {
    background: #616308;
    color: #fff;
    transition: color .5s;
}

.link-personnal:hover {
    background: #616308;
    color: #aaa;
}

.line1, .line2, .line3 {
    width: 23px;
    height: 3px;
    margin: 5px;
    color: white;
    transition: all .4s;
}

.change .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}
.change .line2 {
    opacity: 0;
}
.change .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

.change-line1 {
    width: 23px;
    height: 3px;
    margin: 5px;
    color: white;
    transition: all .4s;
    transform: rotate(-45deg) translate(-5px, 6px);
}

.custom-navbar {
    padding: 5px, 30px;
    background: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.7));
}

.navBarFadeIn {
    animation-name: navBarFadeIn;
    animation-duration: 0.3s 
}

.navBarFadeOut {
    animation-name: navBarFadeOut;
    animation-duration: 0.3s 
}

/* end of navbar */

/* banner */
.banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.banner-heading {
    animation-name: anim;
    animation-duration: 2s;
}

.banner-par {
    animation-name: anim;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-fill-mode: backwards;   
}

.nav-register {
    display: inline-block;
    color: white;
    transition: all 0.3s;

}

.nav-register:hover {
    color: black;
    background-color: rgb(54, 35, 35);
}

.icon-account {
    font-size: 1.5rem;
}

.icon-logout {
    font-size: 1.5rem;
    color: rgb(228, 139, 139);
    transition: all 0.3s;
}

.icon-logout:hover {
    opacity: 0.7;
}

@keyframes anim {

    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes navBarFadeIn {

    0% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes navBarFadeOut {

    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@media (max-width:991px) {

    .icon-shop-small-screen {
        display: block;
        position: absolute;
        top: -1.7rem;
        left: 13rem;
    }

    @-moz-document url-prefix() {
        .icon-shop-small-screen {
            display: block;
            position: absolute;
            top: -0.2rem;
            left: 10rem;
        }

        li {
            list-style: none;
        }
      }
      

    .fa-shopping-cart {
        font-size: 1rem;
    }
  }

@media (max-width:968px) {

    .fa-shopping-cart {
        font-size: 1.2rem;
        transition: all .3s;
    }
  }
/* end of banner */

/* end header */
