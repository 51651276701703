img {
    image-rendering: optimizeQuality;
    }

/* Common Title */
.common-title {
    display: inline-block;
    font-size: 2rem;
    font-weight: 500;
    text-shadow: 2px 2px 5px #aaa;
    transition: all .5s;
}

.common-title:first-letter {
    font-family: 'Great Vibes', cursive;
    font-size: 4rem;
    margin-right: 10px;
    font-style: none;
}

.separator_show {
    display: inline-block;
}

.separator_hide {
    display: none;
}

/* End Common Title */

/** CATEGORIES OBJETS */

.gallery-list-item {
    color: #777;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
}

.gallery-list-item:hover {
    color: #70aed2;
}

.active-item {
    color: #70aed2;
}


/** LISTE DES OBJETS */
.shop {
    margin-top: 60px;
}


.gallery-list-item {
    color: #777;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
}

.gallery-list-item:hover {
    color: #70aed2;
}

.active-item {
    color: #70aed2;
}


.gallery-img {
    backface-visibility: hidden;
    transition: all .5s;
    background-color: rgb(36, 34, 34);
    margin-top: .5rem;
}

.gallery-img:hover {
    transform: scale(1.01);
    z-index: 100;
    opacity: 1.0;
    cursor: pointer;
}
.gallery-img-miniature {
    backface-visibility: hidden;
    transition: all .35s;
    background-color: rgb(46, 38, 38);
    margin-top: .3rem;  
}

.gallery-img-miniature:hover {
    transform: scale(1.05);
    z-index: 100;
    opacity: 1.0 !important;
    cursor: pointer;
}

.img-item {
    padding: 0 8px;
}

.gallery-item {
    background-color: rgb(244, 239, 239);
    margin-bottom: 1rem;
    border-radius: 2%;
    border: .1rem solid rgba(87, 35, 35, 0.075);
    box-shadow: 0rem 0rem 0rem rgba(218, 171, 171, 0.75);
    overflow: hidden;
}

.gallery-item-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    padding: .2rem 0;
}

.gallery-item-description {
    padding: 0rem .6rem;
}

.underline-dark {
    width: 150px;
    border: 3px solid #444;
    margin: auto;
}

.categoty-nbt-items {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;  
}

@media (max-width:991px) {

    .gallery-item {
        margin-top: 1rem;
    }
}

@media (max-width:576px) {

    .customInput {
        padding-left: 5px !important;
        padding-right: 0px !important;
    }
}

@media (max-width:500px) {


    .common-title {      
        font-size: 1rem;
    }
    .common-title:first-letter {
        font-size: 2rem;
        margin-right: 0px;
    }

    .shop {
        margin-top: 45px;
    }

    .gallery-list-item {
        margin-top: 3px;
    }
}

@media (max-width:340px) {

    .is-closed, .is-open {
        margin-right: 4px;
        margin-left: 4px;
    }
}

/** Gestion du floating bouton **/
.btn-custom {
    position: fixed;
    border-radius: 50%;
    bottom: 1rem;
    right: 1rem;
    opacity: 0.7; 
  }

  .lb-nav a.lb-next,
.lb-nav a.lb-prev{
  opacity:.3;
}

.Collapsible {
    background-color: rgb(255, 255, 255);
    margin-bottom: 1rem;
}


  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid rgb(255, 254, 254);
    border-top: 0;
  }

  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: rgb(62, 56, 56);
    position: relative;
    border: 1px solid rgb(50, 8, 8);
    padding: 10px;
    background: rgb(231, 236, 245);
  }  

  .Collapsible__trigger:after {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

  .Collapsible__trigger:hover {
      cursor: pointer;
  }