.category-list {
    width: 50%;
    background-color: antiquewhite;
}

.category-item {
    justify-content: center;
    align-items: center;
    background-color: antiquewhite;
}

.category-text {
    font-weight: bold;
}
.category-text-product {
    font-size: .85rem;
    font-weight: 200;
}

.action-icon {
    display: flex;
    flex-direction: row;
}

.icon {
    cursor: pointer;
    transition: all .3s;
}

.icon:hover {
    opacity: 0.7;
}

.category-edit-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(41, 41, 38);
    transition: all 0.4s;
}

.category-edit-name {
    color: rgb(245, 219, 185);
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
}

.category-edit-label {
    color: rgb(231, 229, 226);
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.category-edit-input {
    width: 90%;
    margin: auto;
}

.Collapsible {
    background-color: rgb(255, 255, 255);
    margin-bottom: 1rem;
}

.delete-button {
    background-color: antiquewhite;
    border: none;
}
.delete-button.focus, .delete-button:focus {
    outline: 0;
    box-shadow: none!important;
}

.button-confirm-delete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 5;
}

.button-add {
    background-color: rgb(9, 12, 14);
    border: none;
    cursor: pointer;
}

.button-add.focus, .button-add:focus {
    outline: 0;
    box-shadow: none!important;
}

.add-category-div-close {
    height: 0rem;
    opacity: 0;
    transition: all .6s;
}

.add-category-div-open {
    height: 18rem;
    opacity: 1;
}

.delete-category-div-close {
    height: 0;
    opacity: 0;
    transition: all .6s;
}

.delete-category-div-open {
    height: 2.1rem;
    opacity: 1;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.fa-plus-circle {
    transition: all .3s;
}

.fa-plus-circle:hover {
    opacity: .7;
}

@media (max-width:1100px) {
    .category-item {
        justify-content: start;
    }

    .category-list {
        width: 80%;
    }

}

@media (max-width:500px) {
    .category-item {     
        justify-content: start;
    }

    .category-list {
        width: 100%;
    }
}