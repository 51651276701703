.personnal-infos {
    width: 100%;
}

.personnal-banner {
    background-color: rgb(245, 240, 233);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}


.personnal-block {
    width: 50%;
    margin-left: 10%;
    margin-top: 0.7rem;
}

@media (max-width:768px) {

    .personnal-block {
        width: 70%;
    }
}

@media (max-width:450px) {

    .personnal-block {
        margin-left: 1%;
        width: 90%;
    }
}